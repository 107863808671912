<template>
  <v-card class="pa-1 pb-0">
    <v-container fluid class="pa-0">
      <v-data-iterator
        :items="payload.items"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template #header>
          <v-toolbar flat class="py-0" height="55" :color="($vuetify.theme.dark) ? '' : 'grey lighten-5'">
            <v-layout row wrap align-center class="ma-0 mx-n1">
              <v-flex class="d-flex align-center">
                <v-btn outlined class="mr-2" @click="$router.go(-1)" small v-if="payload.showBackBtn">
                  <v-icon size="20" left> mdi-chevron-left </v-icon>
                  <span> Back </span>
                </v-btn>
                <span class="font-weight-black mx-4" v-if="payload.title"> {{ payload.title }} </span>
                <v-btn :color="`${ payload.create.color || 'success darken-1' }`" class="mr-2" tile
                v-if="payload.create && (payload.create.show ? payload.create.show() : true)"
                @click="payload.create.click ? payload.create.click() : payload.create()">
                  {{ payload.create.text || 'Create' }}
                </v-btn>
                <v-select outlined dense style="max-width: 200px" v-model="sortBy" hide-details :items="headers.filter((header) => (header && (header.sortable !== false)))"
                label="Sort by" class="mr-2 align-center" v-if="payload.items && payload.items.length">
                  <template #append-outer>
                    <v-icon @click="sortDesc = !sortDesc" size="22">
                      {{ sortDesc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                    </v-icon>
                  </template>
                </v-select>
              </v-flex>
              <v-flex class="d-flex justify-end align-center mr-1">
                <slot name="extraDetails"></slot>
              </v-flex>
              <v-flex lg2 class="d-flex align-center justify-end">
                <v-menu offset-y :close-on-content-click="true">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-on="on" v-bind="attrs" class="text-capitalize ml-2" small outlined
                      v-if="(payload.items && payload.items.length) && listOfViewMenu.length > 2"
                    >
                      <v-icon> {{ viewType.icon || 'mdi-format-list-text' }} </v-icon>
                      <v-icon right v-if="listOfViewMenu.length > 1"> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense v-if="listOfViewMenu.length > 1">
                    <v-list-item-group mandatory>
                      <v-list-item v-for="(item, index) in listOfViewMenu" :key="index" @click="viewType = item"
                      class="pl-3 pr-4">
                        <v-list-item-action class="ma-0 mr-1">
                          <v-icon size="20"> {{ item.icon }} </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <v-btn outlined x-small class="ml-2" @click="(payload.filter === false) ? '' : onClickFilter()"
                v-if="payload.filter">
                  <v-icon size="20"> mdi-filter </v-icon>
                </v-btn>
                <v-menu offset-y :close-on-content-click="true" v-if="payload.config">
                  <template #activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" outlined x-small class="ml-2"
                    @click="$_isArray(payload.config) ? '' : payload.config && payload.config()">
                      <!-- <v-icon size="20"> {{ $_isArray(payload.config) ? 'mdi-view-dashboard' : 'mdi-cog' }} </v-icon> -->
                      <v-icon size="20"> mdi-cog </v-icon>
                      <v-icon right v-if="$_isArray(payload.config)"> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense v-if="payload.config && payload.config.length">
                    <v-list-item
                      v-for="(configItem, index) in payload.config" :key="index"
                      v-show="(typeof configItem.show === 'boolean') ? configItem.show : true"
                      @click="configItem.click && configItem.click()"
                      class="pl-3 pr-4"
                    >
                      <v-list-item-action class="ma-0 mr-1" v-if="configItem.icon">
                        <v-icon :size="configItem.iconSize || 20" :color="configItem.iconColor"> {{ configItem.icon }} </v-icon>
                      </v-list-item-action>
                      <v-list-item-title class="caption">
                        {{ configItem.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-toolbar>
        </template>

        <template #default="props">
          <!-- list view -->
          <v-card v-if="viewType.value === 1" tile flat>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left" v-for="(header, index) in headers" :key="index" v-show="header.show ? header.show() : header" :width="header.width">
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in props.items" :key="item._id">
                    <td v-for="(header, index) in headers" :key="index" v-show="header.show ? header.show(item[header.value]) : header">
                      <template v-if="header.slot">
                        <slot :name="header.slot" :header="header" :item="item"></slot>
                      </template>
                      <!-- date -->
                      <template v-else-if="header.type === 'DATE'">
                        <span> {{ $_formatDate(item[header.value]) }} </span>
                      </template>
                      <!-- unix-date -->
                      <template v-else-if="header.type === 'UNIX-DATE'">
                        <span> {{ $_utcUnixToLocalDateTime(item[header.value], 'DD.MM.YYYY') }} </span>
                      </template>
                      <!-- boolean -->
                      <template v-else-if="header.type === 'BOOLEAN'">
                        <v-switch v-model="item[header.value]" v-if="header.type === 'BOOLEAN'" :color="item[header] ? 'error' : 'success'"
                        inset hide-details class="mb-1"></v-switch>
                      </template>
                      <template v-else-if="header.type === 'SELECT'">
                        {{ header.condition({ header, item }) }}
                      </template>
                      <!-- actions -->
                      <template v-else-if="header.value === 'actions'">
                        <template v-for="(action, index) in payload.actionsList">
                          <v-btn v-show="action.show ? action.show(item) : true" icon x-small :title="action.title"
                          @click="action.click(item)" :key="index" :color="action.color ? action.color : ''" fab>
                            <v-icon v-text="action.icon" :size="action.iconSize || 17"></v-icon>
                          </v-btn>
                        </template>
                      </template>
                      <template v-else> 
                        <template v-if="header.value && header.value.includes('.')"> {{ getNestedObject(item, header.value) }} </template>
                        <template v-else>
                          {{ item[header.value] }}
                        </template>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <!-- card view -->
          <v-container fluid class="pa-1 pt-0" v-else-if="viewType.value === 2">
            <v-row no-gutters>
              <v-col v-for="(item, index) in props.items" :key="index" cols="12" sm="6" md="4" lg="3" class="pa-1">
                <v-card elevation="2" class="pa-1">
                  <v-card-title class="subtitle-1 font-weight-bold pa-2">
                    <span class="px-1"> {{ item.name }} </span>
                    <v-spacer></v-spacer>
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item v-for="(key, index) in headers.filter((item) => item.value !== 'actions')" :key="index">
                      <v-list-item-content :class="{ 'blue--text': sortBy === key }"> {{ key.text }} : </v-list-item-content>
                      <v-list-item-content class="align-end" :class="{ 'blue--text': sortBy === key }">
                        {{ item[key.value] }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <!-- profile view -->
          <v-container fluid class="pa-4 pt-2" v-else>
            <v-row>
              <v-col cols="12" lg="3" v-for="(item, index) in props.items" :key="index" class="pa-1" @click="payload.addClickHandler(item) || null">
                <social-widget top-nav :icon="payload.moduleIcon ? payload.moduleIcon : ''" v-bind="item" :color="item.color || ''" :avatar="item.avatar || ''"
                 :menuItems="payload.actionsList" :modelObj="item"></social-widget>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template #footer v-if="(payload.items && payload.items.length)">
          <v-card tile flat class="pl-3 pr-1 py-1" :color="($vuetify.theme.dark) ? '' : 'grey lighten-5'">
            <v-layout row wrap align-center class="ma-0">
              <v-flex lg4>
                <span :class="['caption', ($vuetify.theme.dark) ? 'grey--text text--lighten-2' : 'grey--text text--darken-2']"> Results per page </span>
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn dark text color="primary" class="font-weight-bold" style="margin-top: 2px;" v-bind="attrs" v-on="on">
                      {{ itemsPerPage }}
                      <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage(number)">
                      <v-list-item-title> {{ number }} </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex lg6 class="text-right">
                <span :class="['mr-3 caption', ($vuetify.theme.dark) ? 'grey--text text--lighten-2' : 'grey--text text--darken-2']"> Page {{ page }} - {{ numberOfPages }} of ( <span class="font-weight-bold"> {{ payload.items.length }} records </span> ) </span>
                <v-btn icon dark color="blue darken-3" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn icon dark color="blue darken-3" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <!-- <span class="font-weight-medium caption"> ({{ payload.items.length }} Records) </span> -->
              </v-flex>
            </v-layout>
          </v-card>
        </template>

        <template #no-data>
          <v-card height="50vh" flat>
            <v-container fill-height>
              <v-row no-gutters align="center" justify="center">
                <v-col cols="5">
                  <no-data></no-data>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    props: {
      headers: Array,
      payload: Object
    },
    data () {
      return {
        viewType: {
          name: 'list',
          text: 'List',
          icon: 'mdi-format-list-text',
          value: 1
        },
        itemsPerPageArray: [5, 10, 20, 50],
        search: '',
        filter: {},
        // preCustomizedItems: [],
        customDesign: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 10,
        sortBy: 'name'
      }
    },
    components: {
      'social-widget': () => import ('@/components/SocialWidget.vue'),
      'no-data': () => import ('@/components/NoData.vue')
    },
    mounted () {
      if (this.payload.defaultView && this.listOfViewMenu.length)
        this.viewType = this.listOfViewMenu.find((item) => item.name === this.payload.defaultView) || this.listOfViewMenu[0]
    },
    computed: {
      ...mapGetters(['filterItem']),
      numberOfPages () {
        return Math.ceil(this.payload.items.length / this.itemsPerPage)
      },
      listOfViewMenu () {
        const views = [{
          name: 'list',
          text: 'List',
          icon: 'mdi-format-list-text',
          value: 1
        }, {
          name: 'card',
          text: 'Card',
          icon: 'mdi-card-bulleted-outline',
          value: 2
        }, {
          name: 'profile',
          text: 'Profile',
          icon: 'mdi-account-box-outline',
          value: 3
        }]
        if (this.payload.views && this.payload.views.length)
          return views.filter((viewItem) => this.payload.views.filter((item) => item.show).map((item) => (item && item.name)).includes(viewItem.name))
        return views
      }
    },
    methods: {
      getNestedObject (nestedObj, path) {
        let pathArr = path.split('.')
        var model = pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj)
        return model
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      selectedArrayValue (array, condition, outputProperty) {
        if (!array || !array.length) return ''
        const result = array.find((listItem) => listItem[condition[0]] === condition[1])
        return (result && result[outputProperty]) ? result[outputProperty] : ''
      },
      onClickFilter () {
        const payloadHeaders = this.$_copy(this.headers)
        for (const headerIndex in payloadHeaders) {
          if (!payloadHeaders[headerIndex].type) payloadHeaders[headerIndex].type = 'TEXT'
          if (payloadHeaders[headerIndex].value === 'actions') payloadHeaders.splice(headerIndex, 1)
        }

        this.$root.$emit('sideNavBar', {
          model: {
            width: '280',
            header: {
              text: 'Filters',
              class: 'indigo darken-4 white--text pa-3 pl-4 font-weight-medium'
            },
            // fields: payloadHeaders
            fields: this.getFilterHeaders(payloadHeaders)
          },
          callback: ({ filterItems, queryObj }) => {
            this.$emit('filter', { filterItems, queryObj })
            // save filter
            this.$store.commit('updateFilter', { name: this.payload.filter, payload: filterItems })
          }
        })
      },
      getFilterHeaders (payloadHeaders) {
        const filters = this.filterItem(this.payload.filter) // get stored filters value from store
        if (filters && filters.length) {
          const resultArray = [...filters]
          payloadHeaders.forEach((header) => {
            if (!filters.find((item) => item.value === header.value)) resultArray.push(header)
          })
          return resultArray
        }
        return payloadHeaders
      }
    }
  }
</script>